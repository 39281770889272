<template>
  <div>
    <dashboard-page-title :showSearch="false" :showFilter="false" :showMainActions="false" @addEvent="$router.push({name: 'addMerchant'})">
    {{ $t('offers.add') }}
    </dashboard-page-title>
    <ValidationObserver v-slot="{ handleSubmit }">
      <b-form @submit.prevent="handleSubmit(onSubmit)">
            <div>
              <b-card class="iq-border-radius-10 mb-3">
                <template v-slot:header>
                  <h5 class="text-primary"><i class="las la-file-alt"></i> {{ $t('offers.details') }}</h5>
                </template>
                <b-row>
                  <b-col md="6" class="mb-3">
                    <input-form :placeholder="$t('main.name')" :label="$t('main.name')" v-model="offer.name" name="product name" validate="required"></input-form>
                  </b-col>
                  <b-col md="6" class="mb-3">
                    <main-select :options="offerTypeOptions" :label-title="$t('offers.offerType')" v-model="offer.type" :reduce="i => i.value" label="text"/>
                  </b-col>
                  <b-col md="6" v-if="offer.type === 'date'" class="mb-3">
                    <b-form-group
                        :label="$t('offers.offerStart')"
                        :label-for="$t('offers.offerStart')"
                        class="position-relative"
                        :label-class="'is_required'"
                    >
                      <validation-provider
                          :name="'start_date'"
                          rules="required"
                          :bails="false"
                          #default="{errors}"
                      >
                        <i class="las la-calendar position-absolute text-gray font-size-20 datePickerIcon"></i>
                        <flat-pickr v-model="offer.start_date" :config="config" :class="['form-control bg-white date-error' , { 'is-invalid': errors.length > 0 }]"></flat-pickr>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6" v-if="offer.type === 'date'" class="mb-3">
                    <b-form-group
                        :label="$t('offers.offerEnd')"
                        :label-for="$t('offers.offerEnd')"
                        class="position-relative"
                        :label-class="'is_required'"
                    >
                      <validation-provider
                          :name="'end_date'"
                          rules="required"
                          :bails="false"
                          #default="{errors}"
                      >
                        <i class="las la-calendar position-absolute text-gray font-size-20 datePickerIcon"></i>
                        <flat-pickr v-model="offer.end_date" :config="config" :class="['form-control bg-white date-error' , { 'is-invalid': errors.length > 0 }]"></flat-pickr>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>
              <b-card class="iq-border-radius-10 mb-5">
                <template v-slot:header>
                  <h5 class="text-primary"><i class="las la-boxes"></i> {{ $t('products.products') }}</h5>
                </template>
                <b-row>
                  <b-col md="12" class="mb-5">
                    <main-select :label-title="$t('products.products')" multiple :options="allProducts" dir="rtl"
                                 v-model="selectedProduct" label="name"
                                 :selectable="option => !selectedProduct.includes(option.id)"
                                 :reduce="product => product.id" @deselected="removeProduct" @selecting="addProduct"
                    />
                    <div v-for="(item, key) in offer.flash_sale_products" :key="key" class="mb-3 bg-gray-light iq-border-radius-5 p-3">
                       <div class="w-100 d-flex gap_1 align-items-center">
                         <img class="avatar-90 iq-border-radius-5" :src="item.product.featured_image"  :alt="item.product.name"/>
                         <div>
                           <p class="text-primary font-weight-bold mt-2 m-0">{{item.product.name}}</p>
                           <p class="text-primary font-weight-bold m-0"><span class="text-dark d-inline-block mx-1">{{ $t('main.price') }} </span>({{ $t('main.currency', { num: item.product.price }) }})</p>
                         </div>
                       </div>
                      <b-row class="mt-4 mb-5">
                        <b-col lg="4" class="mb-3">
                          <input-form placeholder="السعر" label="السعر بعد الخصم" @input='changePrice(item)' v-model="item.price"  name="price" validate="required|numeric"></input-form>
                        </b-col>
                        <b-col lg="4" class="mb-3">
                          <input-form placeholder="النسبة %" label="النسبة" @input='changePercentage(item)' v-model="item.percentage" name="percentage" validate="required|numeric"></input-form>
                        </b-col>
                        <b-col lg="4" class="mb-3" v-if="offer.type === 'quantity'">
                          <input-form placeholder="الكمية" label="الكمية" v-model="item.quantity" name="quantity" validate="required|numeric"></input-form>
                        </b-col>
                      </b-row>
                    </div>
                  </b-col>
                </b-row>
              </b-card>
            </div>
            <div class="form-actions">
              <b-button variant="outline-primary" @click="$router.back()">{{ $t('main.back') }}</b-button>
              <b-button v-if="$route.name !== 'showProduct'" variant="primary" type="submit" :disabled="loadingSubmit">
                <span v-if="!loadingSubmit">{{ $t('main.save') }}</span>
                <spinner-loading v-else text="loading"></spinner-loading>
              </b-button>
            </div>
      </b-form>
    </ValidationObserver>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import productServices from '@/modules/products/services/products'
import offersServices from '@/modules/offers/services/offers'
export default {
  components: { },
  mounted () {
    core.index()
  },
  data () {
    return {
      offer: {
        name: '',
        start_date: '',
        end_date: '',
        type: 'date',
        flash_sale_products: []
      },
      loadingSubmit: false,
      config: {
        minDate: 'today',
        altInput: true,
        dateFormat: 'Y-m-d',
        enableTime: true
      },
      allProducts: [],
      selectedProduct: [],
      offerTypeOptions: [
        { text: this.$t('offers.typeStatus.date'), value: 'date' },
        { text: this.$t('offers.typeStatus.quantity'), value: 'quantity' }
      ]
    }
  },
  methods: {
    changePrice (obj) {
      obj.percentage = ((Number(obj.product.price) - Number(obj.price)) / Number(obj.product.price)) * 100
    },
    changePercentage (obj) {
      // obj.price = (Number(obj.percentage) / 100) * Number(obj.product.price)

      obj.price = (Number(obj.product.price) - Number(obj.percentage))
    },
    getAllMerchantProducts () {
      productServices.getAllMerchantProducts().then(res => {
        this.allProducts = res.data.data
      })
    },
    addProduct (productData) {
      this.offer.flash_sale_products.push({
        product: productData,
        price: '',
        percentage: '',
        quantity: ''
      })
    },
    removeProduct (productData) {
      var position = this.offer.flash_sale_products.findIndex(product => product.product.id === productData.id)
      this.offer.flash_sale_products.splice(position, 1)
    },
    onSubmit () {
      offersServices.addNewFlashSale(this.offer).then(res => {
        core.showSnackbar(res.data.message)
        this.$router.push({ name: 'offers' })
      })
    }
  },
  created () {
    this.getAllMerchantProducts()
  }
}
</script>
0
